const WalletModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');
const WalletDeposit = () => import(/* webpackChunkName: "wallet-deposit" */ './views/WalletDeposit.vue');
const WalletWithdraw = () => import(/* webpackChunkName: "add-account" */ './views/WalletWithdraw.vue');
const DepositPaymentMethod = () => import(/* webpackChunkName: "deposit-payment-method" */ './views/DepositPaymentMethod.vue');


const WalletRoutes = {
    path: '/',
    component: WalletModule,
    children: [
      {
        path: 'wallet-deposit',
        name: 'wallet-deposit',
        component: WalletDeposit,
      },
      {
        path: 'wallet-withdraw',
        name: 'wallet-withdraw',
        component: WalletWithdraw,
      },
      {
        path: 'deposit-payment-method',
        name: 'deposit-payment-method',
        component: DepositPaymentMethod,
      },
    ],
}

export default WalletRoutes;