export const routes = {
    user: {
        GET_SITE_SETTINGS: 'api/v1-site-settings',
        WALLET_BALANCE: 'wallet',
        AUTH_REFRESH: 'api/auth-refresh',
        LOGIN_API:'api/login',
        DEMO_LOGIN_API:'api/demo-user-login',
        LOGIN_WITH_OTP:'api/login-with-otp',
        LOGOUT_API:'api/logout',
        GET_FAVOURITE_LIST:'api/favorite-list',
        GET_EVENT_EXPOSURES: 'api/get-event-book-and-fancy-exposures-by-event-id/:matchId',
        GET_OPEN_BETS_BY_EVENT_ID: 'api/get-open-bets-by-event-id',
        SAVE_BETS: 'api/save-bet',
        BET_CASHOUT: 'api/cashout',
        GET_FANCY_BOOK: 'api/get-fancy-book',
        MAKE_FAVOURITE: 'api/make-match-favorite',
        CHANGE_STAKE_VALUES: 'api/change-stake-values',
        GET_WALLET_GETWAY: 'api/get-payment-getway-iframe-url',
        GET_PAYMENT_WALLET_GATEWAY: 'api/get-payment-getway-token',
        GET_CASINO_GAMES: 'api/get-casino-games',
        GET_MY_MARKETS: 'api/get-my-markets/:sport_type',
        EVENT_BET_LIST: 'api/event-bet-list',
        GET_PREMIUM_FANCY_URL: 'api/get-sap-premium-url/:sportId/:eventId',
        GET_SITE_RULES: 'api/get-site-rules',
        UPDATE_WALLET_TYPE: 'api/wallet-switch',
        SEND_OTP: 'api/send-otp',
        REGISTER: 'api/register',
        FORGOT_PASSWORD: 'api/forgot-password',
        CHANGE_MY_PASSWORD: 'api/change-my-password',
        GET_SITE_VERSION: 'api/site-status-version',
        GET_MATKA_SINGLE_BETS: 'api/get-matka-single-bets/:id',
        GET_STATEMENTS: 'api/get-statements',
        OPEN_BETS_EVENT_LISTING: 'api/open-bets-event-listing',
        GET_PROFIT_LOSS: 'api/get-profit-loss',
        GET_PROFIT_LOSS_DETAIL: 'api/get-profit-loss-detail',
        GET_MARKET_BETS: 'api/get-market-bets',
        GET_LIVE_POCKER_URL: 'api/get-live-poker-url/:game_code',
        DREAM_CASINO: 'api/dreamcasino/load-game/:game_code/:sm_id',
        UNIVERSE_CASINO: 'api/universe/',
        GET_SPORTS_BOOK_URL:  'api/get-sap-sportsbook-url',
        GET_CHATBOT_IFRAME: 'api/chatbot/get-iframe-url',
        GET_CHATBOT_IFRAME_GUEST: 'api/chatbot/get-iframe-url-for-guest',
        SAVE_WORLI_MATKA_BET: 'api/save-worli-matka-bet',
        GET_REFER_CODE: 'api/get-refer-code',
        PLACE_ORDER: 'api/place-order',
        SEARCH: 'api/search',
        GET_MARKET_ANALYSIS: 'api/get-market-analys-data',
        GET_ALL_MARKET_BOOK: 'api/get-all-event-book-and-fancy-exposures-by-event-id',
    },
    sports: {
        INPLAY_MATCHES_LIST : 'v1/events/matches/inplay',
        MATCHES_BY_SPORT_ID: 'v1/events/matches/:sport_id',
        GET_SPORTS: 'v1/sports/management/getSport',
        GET_MATCH_DETAILS: 'v1/events/matchDetails/:event_id',
        FIND_BY_IDS: 'v1/events/findByIds',
        INPLAY_MATCHES_COUNT: "v1/events/count",
        GET_MATKAS: '/v1/worli/public/matches',
        GET_MATKA_MARKETS: '/v1/worli/public/matches/:id/all/markets',
        GET_UPCOMING_SPORTS:'v1/events/sports/all',
        // GET_MATKA_SINGLE_BETS: '/get-matka-single-bets/:id',
    },
    wallet: {
        // Deposit
        PARENT_PAYMENT_METHODS :'api/user/get-agent-banks',
        WALLET_DEPOSIT :'api/user/deposit-request',
        GET_AUTO_UTR :'/api/user/get-auto-utr',
        // Withdraw , Deposit
        CHANGE_STAKE_VALUES :'api/user/stakes/create',
        GET_STAKES :'api/user/stakes/list',
        WALLET_LIMITS: 'api/wallet-limits',
        // Withdraw
        PAYMENT_METHODS :'api/user/payment-methods',
        ADD_ACCOUNT :'/api/user/bank/create',
        WALLET_WITHDRAW :'api/user/withdraw-request',
        ACCOUNT_DETAILS :'api/user/bank/list',
        DELETE_ACCOUNT :'/api/user/bank/delete',
        BANK_DETAILS :'api/agent/bank-list',
        ONLY_BANK_STATUS :'api/user/withdrawal-only-bank-status',
        // History
        WALLET_HISTORY :'api/user/wallet-history-list/',
        CANCEL_REQUEST :'api/user/cancel-request',
        CHECK_WITHDRAWAL_PASSWORD :'api/check-withdrawal-password',
        SENT_OTP_TO_MOBILE :'api/sent-otp-to-mobile',
        RESET_WITHDRAWAL_PASSWORD :'api/reset-withdrawal-password',
        VERIFY_MOBILE_OTP :'api/verify-mobile-otp',
    },
    fantasy: {
        FANTASY_SETUP_DATA: '/get-setup-data',
        GET_REMEMBERED_MATCHES: '/get-remembered-matches',
        ADD_MATCH_REMINDER: '/add-match-reminder',
        REMOVE_MATCH_REMINDER: '/remove-match-reminder',
        PLAYERS_LIST_API: '/get-match-players-list/:matchId',
        GET_MY_STAKES: '/get-my-stakes',
        UPDATE_MY_STAKES: '/update-my-stakes',
        ADD_MY_TEAM: '/add-my-team',
        UPDATE_MYTEAM_NAME: '/update-my-teamname',
        BET_DETAILS: '/get-match-bet-details',
        PLACE_BET: '/place-bet',
        MY_CONTESTS_LIST: '/get-my-contests',
        MY_CONTEST_BET_LIST: '/get-match-teams-and-bet-details',
        CANCEL_BET: '/cancel-bet',
        GET_TEAM_DETAILS: '/get-team-details/:id',
        TUTORIALS_API: '/get-tutorials',
        RESET_MYTEAM: '/reset-my-team'
    },
    bonus: {
        GET_BONUS_LOGS: 'api/v1/get-bonus-log',
        GET_BONUS_LOGS_DETAILS: 'api/v1/get-bonus-log-details',
        GET_USER_LOCKED_BONUS: 'api/v1/get-user-locked-bonus',
        GET_LOCKED_EARNED_BONUS: 'api/v1/get-locked-earn-bonus',
    },
    notification: {
        NOTIFICATIONS_LIST_API: 'api/get-notification',
        MARK_REDIRECT_NOTIFICATION: 'api/redirect-to-notification',
        UNREAD_NOTIFICATION_COUNT: 'api/get-unread-notifications-count',
        SUBSCRIBE_NOTIFICATION: 'api/subscribe-notification',
    }
};