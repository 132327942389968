
import App from "./App.vue";
import router from "./router"
import store from "./store";
import VueCookies from 'vue3-cookies';
import { createApp } from 'vue'
import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/style1.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css"
import "./assets/fontawesome/css/bootstrap-icons.css"
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import "vue-gtranslate/translator.css";
import "vue-awesome-paginate/dist/style.css";
import VueAwesomePaginate from "vue-awesome-paginate";
import './registerServiceWorker'
import { Dropdown, Offcanvas } from 'bootstrap';
import globalProperties from './globalServices';



window.addEventListener('popstate', (event) => {
    if(document.querySelector('.modal.show')) {
        window.location.reload();
    }
});

router.beforeEach((to, from, next) => {
    if(document.querySelector('.modal.show')) {
        next(false);
    }
    const dropdownMenu = document.querySelector('.open-menu-btn .dropdown-menu.show');
    if (dropdownMenu) {
        const headAccElement = document.querySelector('.open-menu-btn .dropdown-toggle');
        const headAcc = Dropdown.getInstance(headAccElement);
        if (headAcc) {
            headAcc.hide();
        }
    }
    const offcanvasElement = document.querySelector('.offcanvas.offcanvas-start.show');
    if (offcanvasElement) {
        const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
        if (offcanvasInstance) {
            offcanvasInstance.hide();
            window.store.dispatch('setMenuShow', true)
        }
    }
    const underMaintenance = store.getters.underMaintenance;
    const isMaintenancePage = to.name === 'Maintenance';
    const siteSettings = store.getters.siteSettings;
    const isLoggedIn = store.getters.isAuthenticated;
    if (underMaintenance) {
        if (!isMaintenancePage) {
            next({ name: 'Maintenance' });
        } else {
            next();
        }
    } else {
        if (isMaintenancePage) {
            next({ name: 'Home' });
        } else {
            if(window.store?.getters.isAuthenticated && !window.store?.getters?.stateUser?.last_login_at && to.name != 'profile'){
                window.router.push({ name: 'profile', params: { type: 'CHANGE_PASSWORD' } });
            }else if (siteSettings && siteSettings.business_type == 1) {
                if (to.path === '/login' || to.path === '/forgot-password' || to.path === '/sign-up') {
                    if (isLoggedIn) {
                        next('/');
                    } else {
                        next();
                    }
                } else {
                    if (isLoggedIn) {
                        next();
                    } else {
                        next('/login');
                    }
                }
            } else if (siteSettings && siteSettings.business_type == 2) {
                if (to.path === '/login' || to.path === '/forgot-password' || to.path === '/sign-up') {
                    if (isLoggedIn) {
                        next('/');
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    }
});
const app = createApp(App);
app.use(globalProperties);
app.use(store);
app.use(router);
app.use(VueCookies);
app.use(VueAwesomePaginate);
app.mount("#app");