<template>
  <div id="load" v-if="getGlobaLoader()">
        <div id="load-inner"><div class="spinner-rotate"></div></div>
  </div>
    <MobileSideMenu v-if="!underMaintenance"/>
  <header v-if="!underMaintenance && $route.name != 'NotFound'" class="" :class="!checkForHeaderShow() ? 'header-0px' : 'header-wapper header-search'" loading="lazy">
    <Header v-if="checkForHeaderShow()"/>
  </header>

  <section :class='sectionClass' class="main-body-container-sec">
    <div class="row g-0">
      <div class="col-0 col-sm-0 col-md-0 col-lg-2">
        <SideMenu v-if="!underMaintenance"/>
      </div>

      <div class="col-12 col-sm-12 col-md-12 col-lg-10">
        <div>
          <router-view v-if="$siteSettings?.data"/>
        </div>
      </div>
    </div>
  </section>  
  <template v-if="!underMaintenance">
    <Footer v-if="$route.name=='Home' || $route.name=='inplay'"/>
  </template>
  <template v-if="!$auth?.status">
    <Login/>
    <SignUp/>
    <ForgetPassword/>
  </template>
	<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications"/>


  <div v-if="successMsg" class="successfully-wrapper" style="z-index: 9999999999999999;"
    ref="successmodal" id="successfullyModal">
    <SuccessModal :message="successMsg" />
  </div>

  <div v-if="errorMsg" style="z-index: 9999999999999999;" class="successfully-wrapper"
    id="wrongtoaster">
    <ErrorModal :message="errorMsg" />
  </div>

  <LanguageSelection/>
  <Offers />

  <BonusInfoModal />
  <DownloadApkModal/>
  <CopyUserIdModal v-if="$auth.status" @open-offer="openOfferBanner()"/>
  

  
</template>

<script>

import { defineAsyncComponent } from 'vue';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import { setHeaders } from '@/shared/services/headers';
import axios from 'axios';
import socketService from "./socket/sport-socket-service";
import { mapGetters } from 'vuex';
import notificationAlert from '@/shared/services/notificationAlert';

export default {
  name: 'App',
  extends: notificationAlert,
  data() {
    return {
      onLine: navigator.onLine,
      showBackOnline: false,
    }
  },
  components: {
    NotificationAlert: defineAsyncComponent(() =>
      import('./shared/components/modal/NotificationAlert.vue')
    ),
    Header: defineAsyncComponent(() =>
      import('@/shared/components/header/Header.vue')
    ),
    MobileSideMenu: defineAsyncComponent(() =>
      import('@/shared/components/side-menu/MobileSideMenu.vue')
    ),
    SideMenu: defineAsyncComponent(() =>
      import('@/shared/components/side-menu/SideMenu.vue')
    ),
    Footer: defineAsyncComponent(() =>
      import('@/shared/components/footer/Footer.vue')
    ),
    Login: defineAsyncComponent(() =>
      import('@/modules/authorization/components/Login.vue')
    ),
    SignUp: defineAsyncComponent(() =>
      import('@/modules/authorization/components/SignUp.vue')
    ),
    ForgetPassword: defineAsyncComponent(() =>
      import('@/modules/authorization/components/ForgetPassword.vue')
    ),
    SuccessModal: defineAsyncComponent(() =>
      import('@/shared/components/modal/SuccessModal.vue')
    ),
    ErrorModal: defineAsyncComponent(() =>
      import('@/shared/components/modal/ErrorModal.vue')
    ),
    LanguageSelection: defineAsyncComponent(() =>
      import('./shared/components/header/LanguageSelection.vue')
    ),
    BonusInfoModal: defineAsyncComponent(() =>
      import('./shared/components/header/BonusInfoModal.vue')
    ),
    Offers: defineAsyncComponent(() =>
      import('./shared/components/header/Offers.vue')
    ),
    DownloadApkModal: defineAsyncComponent(() =>
      import('@/shared/components/modal/DownloadApkModal.vue')
    ),
    CopyUserIdModal: defineAsyncComponent(() =>
			import('./shared/components/modal/CopyUserIdModal.vue')
		),
  },
  computed:{
    ...mapGetters(['eventDetailHideSections', 'siteSettings', 'successMsg', 'errorMsg', 'siteVersion', 'underMaintenance']),
    routePath() {
			return this.$route.name
		},
    sectionClass() {
      if (!this.checkForHeaderShow()) {
        return 'padding-top-0';
      } else if (!this.$auth?.status) {
        if (!this.siteSettings?.announcement) {
          return 'padding-top-101';
        } else {
          return 'padding-top-105';
        }
      } else {
        if(this.$route.name == 'NotFound'){
          return 'padding-top-0';
        }
        if (!this.$siteSettings?.data?.announcement) {
          return this.$siteSettings?.data?.business_type==2 ? 'padding-top-150' : 'padding-top-101';
        } else {
          return this.$siteSettings?.data?.business_type==2 ? 'padding-top-170' : 'padding-top-105';
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch('setGlobalLoader', true);
    //for use on js pages
    this.getSiteVersion();
    window.store = this.$store;
    window.cookies = this.$cookies;
    window.router = this.$router;
    window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
    //for add on all APIs
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
    setHeaders();
    this.$errorToast(null);
		this.$successToast(null);
    if(this.$auth?.status) {
      if(this.$store.getters.stateUser.notificationUnsubscribed==1){
				return;
			}
			if(this.$store.getters.stateUser.notificationSubscribed!=1) {
				this.requestNotificationPermission()
			}
    }
  
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.socketAllEvents();
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    async getSiteVersion() {
			this.$store.dispatch('setGlobalLoader', true);
			this.$apiCall('get', 'GET_SITE_VERSION', null, 'user', { domain: window.location.hostname }).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {
						let resData = response?.data?.data;
						let currentVersion = resData.version;
						let userSystemVersion = this.siteVersion || 0;		
						if (!userSystemVersion || currentVersion != userSystemVersion || !this.$siteSettings.data) {
							this.$store.dispatch('setSiteVersion', currentVersion);
							this.getSiteSettingsServiceCall();
						} else {
							this.siteSettingCommon(this.$siteSettings.data);
						}
						if (resData.maintenance) {
							const { from_date, to_date } = resData.maintenance;
							const fromDateTime = new Date(from_date).getTime();
							const toDateTime = new Date(to_date).getTime();
							const currentDateTime = Date.now();

							const isInMaintenanceWindow = fromDateTime <= currentDateTime && currentDateTime <= toDateTime;

							if (fromDateTime && toDateTime && isInMaintenanceWindow) {
								this.$store.dispatch('setUnderMaintenance', resData.maintenance);
								this.$router.push({ name: 'Maintenance' });
							}
						}
						else {
							if(this.underMaintenance) {
								this.$router.push({name:'Home'});
							}
							this.$store.dispatch('setUnderMaintenance', null); 
						}
						this.$store.dispatch('setGlobalLoader', false);
					}
				}
			}).catch(error => {
				if (error) {
					this.$store.dispatch('setGlobalLoader', false);
					this.$errorToast(error[0]);
				}
			});
		},
    checkForHeaderShow() {
			 if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
    getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
    async getSiteSettingsServiceCall() {
      this.$store.dispatch('setGlobalLoader', true);
			this.$apiCall('get', 'GET_SITE_SETTINGS', null, 'user', { domain: window.location.hostname }).then(async response => {
        this.$store.dispatch('setGlobalLoader', false);
        if (response) {
          if (response.status == 200) {
            if (response?.data?.status == 0) {
              this.$errorToast(response.data.debug[0]);
            }
            else {
              let resData = response?.data.data.site_settings
              let casino;
              if(response?.data.data.casino_url) {
                const getCasinoGame = async () => {
                let gameResp = await axios.get(response?.data.data.casino_url)
                return gameResp.data
                };
                casino = await getCasinoGame();
              } else {
                casino = response?.data.data?.casino
              }			
              this.$store.dispatch('setSettings', resData);
              this.$store.dispatch('setGamesData', casino);
              this.siteSettingCommon(resData);		
            }
          }
        }
      }).catch(error => {
        this.$store.dispatch('setGlobalLoader', false);
        if (error) {
          this.$errorToast(error[0]);
        }
      });
    },
    openOfferBanner(){
        this.$helper.openModal('#offer_pop_up')
    },

    async siteSettingCommon(siteSetting) {
			try {        
				if (siteSetting.language_translate_list) {
					this.$setCurrentLangData(siteSetting.language_translate_list, siteSetting.language_code, siteSetting.aws_url)
					this.$store.dispatch('setLanguagesData', siteSetting.language_translate_list);
				}
				this.setManifest(siteSetting);
          // Create FAVICON
          const favElement = document.createElement('link');
          favElement.rel = 'icon';
          favElement.href = siteSetting.aws_url + siteSetting.storage_path.domain_image + siteSetting.fav_logo;
          document.head.insertAdjacentElement('beforeend', favElement);
          const title = document.getElementById("title");
          title.text = siteSetting.domain_name;
          let is_offer = localStorage.getItem("is_offer");
          if(siteSetting.meta_tags) {
					document.head.innerHTML += siteSetting.meta_tags;
				};
        if(siteSetting.g_tags) {
					this.setGoogleAnalyticsTag(siteSetting.g_tags);
				};
        if(siteSetting.site_map) {
					const sitemapLink = document.createElement("link");
					sitemapLink.rel = "sitemap";
					sitemapLink.type = "application/xml";
					sitemapLink.title = "Sitemap";
					sitemapLink.href = siteSetting.aws_url + siteSetting.storage_path.site_map + siteSetting.site_map;
					document.head.appendChild(sitemapLink);
				};
        try {
					if (siteSetting.front_css != null && siteSetting.front_css != '') {
						const styleElement = document.createElement('style');
						styleElement.type = 'text/css';
						styleElement.appendChild(document.createTextNode(siteSetting.front_css));
						document.head.insertAdjacentElement('beforeend', styleElement);
					}
				} catch (error) {
          console.log("ERROR 1: ", error);
          }
          if (this.$auth?.status){
            this.getFavourites()
            this.getWalletBalance();
            setTimeout(() => {
              let userIdList = this.$store.getters.userIdList;
              let login_type = localStorage.getItem('UserIdorLogin')
              if(login_type == 2){
                let userIdList = this.$store.getters.userIdList;
                if (!userIdList || (userIdList && userIdList?.length > 0 && userIdList.includes(this.$auth.user.userid))) {
                    userIdList = [];
                }
                if (!userIdList.includes(this.$auth.user.userid)) {
                    this.$store.dispatch('setUserIdList', this.$auth.user.userid);
                } 

                if (this.$auth?.status && siteSetting.offer != null && is_offer == null && this.$siteSettings?.data?.business_type == 2 && this.$auth.user.last_login_at) {
                    this.openOfferBanner();
                }
              }
              else if (login_type == 1 && this.$auth.user.last_login_at && (!userIdList || (userIdList && !userIdList.includes(this.$auth.user.userid)))) {
                  this.$helper.openModal('#useridmodal')
              }else if (this.$auth?.status && siteSetting.offer != null && is_offer == null && this.$siteSettings?.data?.business_type == 2 && this.$auth.user.last_login_at) {
                  this.openOfferBanner();
              }
          }, 500);
          }
				} catch (error) {          
					this.$errorToast(error);
				}
		},
    setGoogleAnalyticsTag(gTag) {
				let script1 = document.createElement('script');
				script1.async = true;
				script1.src = `https://www.googletagmanager.com/gtag/js?id=${gTag}`;
	
				let script2 = document.createElement('script');
				script2.innerHTML = `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${gTag}');
				`;
	
				document.head.appendChild(script1);
				document.head.appendChild(script2);
			},
    setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);
		},
    getWalletBalance() {
      let authToken = this.$cookies.get(AUTH_TOKEN);
      if (!authToken) return;
			this.$apiCall('post', 'WALLET_BALANCE').then(response => {
        if (response && response.status == 200 && response.data) {
          let user = this.$store.getters.stateUser;
          user.balance = response.data.bl;
          user.exposure = response.data.ex;
          user.bets_count = response.data.bc;
          user.earnBonus = response.data.eb;
          this.$store.dispatch('setUser', user);
          if ((user.exp - this.$dateTime.currentTimestamp()) < 10) {
            this.refreshAuthToken();
          } else {
            setTimeout(function () {
              this.getWalletBalance();
            }.bind(this), 2000);
          }
        } else {
          this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
          this.$store.dispatch('resetAllState');
        }
      }).catch(error => {
        if (error) {
          this.$errorToast(error[0]);
        }
      });
    },
    refreshAuthToken() {
      this.refreshInit = true;
			this.$apiCall('post', 'AUTH_REFRESH').then(response => {
        let user = this.$store.getters.stateUser;
        user.token = response.data.data.token;
        user.exp = response.data.data.exp;
        this.$cookies.set(AUTH_TOKEN, response.data.data.token);
        this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
        window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
        //for add on all APIs
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
        this.$store.dispatch('setUser', user);
        this.refreshInit = false;
        setTimeout(function () {
          this.getWalletBalance();
        }.bind(this), 2000);
      }).catch(error => {
        if (error) {
          this.$errorToast(error[0]);
        }
        this.refreshInit = false;
      });
    },
    updateOnlineStatus(e) {
      const {
        type
      } = e;
      this.onLine = type === 'online';
    },
    socketAllEvents() {
      if (this.$store.getters.stateUser) {
        socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
        socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
        socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

        socketService.on("DepositRequest", (data) => {
          if (data.type == "myId") {
            if (data.notify_type == 1) {
              this.$store.dispatch('setNotification');
              this.$successToast('New Notification...')
            }
          }
        });
        socketService.on("logout", (data) => {
          if (data.type == "logout") {
            // logout user from users
            // if (data.notify_type == 1) {
            // 	this.$store.dispatch('setNotification');
            // 	this.$successToast('New Notification...')
            // }
          }
        });
        socketService.on("banned", (data) => {
          if (data.type == "match_ban") {
            // match ban condition
          } else if (data.type == 'league_ban') {
            // league ban condition
          }
        });

      }
    },
		getFavourites() {
			let data = {
				"game_type": 1
			}
			this.$apiCall('post', 'GET_FAVOURITE_LIST', data).then(response => {
				if (response) {
					if (response.status == 200) {
						if (response?.data?.status != 0) {
							var allData = response.data.data;
							var ids = [];
							for (var i = 0; i < allData.length; i++) {
								ids.push(allData[i].match_id)
							}
							this.$store.dispatch('setFavouriteGames', ids)
						}
					}
				}
			}).catch(error => {
				if (error) {
				}
			});
		},
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    }
  }
}
</script>
