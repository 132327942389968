export default {
    methods: {
        requestNotificationPermission() {
            if (!('Notification' in window)) {
                console.log('This browser does not support notifications.');
                return;
            }
            if (Notification.permission === 'granted') {
                this.getSubscriptionData();
            } else if (Notification.permission !== 'denied') {
                this.openNotificationBox();
            }
        },
        openNotificationBox() {
            setTimeout(() => {
                this.$helper.openModal('#notification-alert');
            }, 3000);
        },
        allowNotifications() {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    this.getSubscriptionData();
                }
            });
        },
        cancelNotifications() {
            let user = this.$store.getters.stateUser;
            user.notificationUnsubscribed = 1;
            this.$store.dispatch('setUser', user);
        },
        async getSubscriptionData() {
            let user = this.$store.getters.stateUser;
            user.notificationSubscribed = 1;
            this.$store.dispatch('setUser', user);
            if ('serviceWorker' in navigator) {
                try {
                    await navigator.serviceWorker.register('/service-worker.js').then(async (registration) => {
                        setTimeout(async () => {
                            if ('PushManager' in window && registration.pushManager) {
                                const subscription = await registration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
                                });
                                this.deviceData = subscription;
                                this.subscribeNotifications();
                            } else {
                                console.warn('PushManager is not available');
                            }
                        }, 1000);
                    }).catch(e => console.log(e));
                } catch (error) {
                    console.error('Service Worker registration failed:', error);
                }
            } else {
                console.warn('Service workers are not supported in this browser.');
            }
        },
        subscribeNotifications() {
            let data = {
                deviceData: this.deviceData,
                domainName: window.location.host,
            };
            this.$apiCall('post', 'SUBSCRIBE_NOTIFICATION', data, 'notification').then(response => {
                this.loadingSignUp = false;
                if (response) console.log(response);
            }).catch(error => {
                this.loadingSignUp = false;
                if (error) this.$errorToast(error[0]);
            });
        }
    }
};