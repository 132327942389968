import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from '@/modules/authorization/store';
import commonStore from '@/shared/store';
import walletStore from '@/modules/wallet/store';
import accountStatement from '@/modules/bets/store';
import fantasyCricketStore from '@/modules/cricket-fight/store';
import accountStore from '@/modules/account/store';


const mutations = {
  resetState(state) {
    state.auth.user = null;
    state.auth.token = null;
    state.auth = {
      user: null,
      chips: null,
      token: null,
      eventManage: null,
      eventDetailHideSections: []
    }
    state.commonStore.siteVersion = null;
  },
}
const actions = {
  resetAllState({ commit }) {
    commit('resetState')
  }
}

export default createStore({
  state: {},
  mutations,
  actions,
  modules: {auth,commonStore,walletStore,accountStatement,fantasyCricketStore, accountStore},
  plugins: [createPersistedState(
    {
      key:'sd:site'
    }
  )]
});