<template>
  <section class="not-found-wrapper">
    <div class="container">
      <div class="not-found-sec">
        <div class="found-img"><img loading="lazy" src="@/assets/images/not-found.jpg" alt="error"></div>
        <div class="found-heading">
          <h1>404</h1>
          <p>Not Found</p>
          <router-link to="/" class="btn_submit_cl">Go To Homepage</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
}

.found-img img {
  width:320px;
}

.found-img{
text-align:center;
}

.found-heading {
  text-align: center;
}
.not-found-wrapper{
  background-color:#fff;
  height:100dvh;
  margin-bottom:-52px;
}

.found-heading h1 {
  color: #000;
  font-size: 80px;
  font-weight: 800;
  line-height:40px;
  margin-bottom: .5rem;
}

.found-heading p {
  color: #707070;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.6
}

.found-heading .btn_submit_cl {
  margin-top: 10px;
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  padding: .5rem .75rem;
  border-radius: 4px;
  background: var(--primary-color);
  color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

.align-items-center {
  align-items: center!important;
  background-color:#fff;
  flex-direction: column;
}
</style>
