import { Modal } from "bootstrap";

export const successToast = (message) => {
    window.store.dispatch('showSuccessModal', message);
};

export const errorToast = (message) => {
    window.store.dispatch('showErrorModal', message);
};

export const helper = {
    showLogin() {
        let login = new Modal('#login-btn');
        login.show();
    },
    openModal(id) {
        setTimeout(() => {
            let modal = new Modal(id);
            modal.show();
        }, 100)
    },
    closeModal(id) {
        setTimeout(() => {
            let closeModal = document.getElementById(id)
            let modal = Modal.getInstance(closeModal);
            if(modal) {
                modal.hide();
            }
        }, 100)
    },
    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    },
    formatNumber(value){
        if (value !== null) {
            return parseFloat(value).toFixed(2);
        } else {
            return 0;
        }
    },
    notNull(item){
        return item !== ('' || null || undefined)
    },
    compareLength: (len, prop) => {
        return prop.length == len
    },
    testRegex(regex, data) {
        return regex.test(data);
    },
    //----- sanitization helpers
    sanitizeDetails(field, prop, sliceLength) {
        const regexMap = {
            userId : /[^\S]/g,
            oldLoginUserId: /[\s]/g,
            password : /[^a-zA-Z0-9!@#$%^&*()_+{}\[\]:;"'<>,.?\/\\|`~=-]/g,
            login : /\s+/g,
            oldLoginPassword: /[\s]/g,
            referCode : /[^0-9]/g,
            withdrawPassword: /[\s]/g
        };
        if (regexMap[field]) {
            let sanitizedValue = prop.replace(regexMap[field], '');
            if (sliceLength) {
                sanitizedValue = sanitizedValue.slice(0, sliceLength);
            }
            return sanitizedValue;
        }
        return prop;
    },
    sanitizeNumber(prop, maxValue) {
        if (prop != '') {
            let stringValue = prop.toString();
            stringValue = stringValue.replace(/[^0-9]/g, '').replace(/[+-]/g, '');
            let sanitizedNumber= '';
            maxValue == 999999 ? sanitizedNumber = stringValue.slice(0,6) ?? '' : sanitizedNumber = stringValue == '' ? '' : Math.max(0, Math.min(maxValue, stringValue)).toString()
            return sanitizedNumber
        }
        return prop;
    },
    addCommas(value) {
        if (!value) return "0";
        var value = parseFloat(value).toFixed(2);
        value += '';
        var x = value.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        var total = x1 + x2;
        return total;
    },
    //----- sanitization helpers
}