const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue'); 
const Statements = () => import(/* webpackChunkName: "statements" */ './views/Statements.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');
const Bets = () => import(/* webpackChunkName: "profit-loss" */ './views/Bets.vue');
const MarketAnalyasis = () => import(/* webpackChunkName: "profit-loss" */ './views/MarketAnalysis.vue');
const ProfitLossDetail = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLossDetail.vue');
const CasinoWinLossDetails = () => import(/* webpackChunkName: "win-loss" */ './components/CasinoWinLossDetail.vue');

const BetsRoutes =   {
    path: '/',
    component: BetsModule,
    children: [
      {
        path: 'statements',
        name: 'statements',
        component: Statements
      },
      {
        path: 'profit-loss',
        name: 'profit-loss',
        component: ProfitLoss
      },
      {
        path: 'bets/:type',
        name: 'bets',
        component: Bets
      },
      {
        path: 'market-analysis',
        name: 'market-analysis',
        component: MarketAnalyasis
      },
      {
        path: 'profit-loss-detail',
        name: 'ProfitLossDetail',
        component: ProfitLossDetail
      },
      {
          path: 'casino-win-loss-details',
          name: 'casino-win-loss-details',
          component: CasinoWinLossDetails,
      }
    ],
  }

export default BetsRoutes;